export const ExperienceContent = [
    {
      "company": "eQ",
      "link": "https://www.1eq.com/home",
      "role": "Software Engineer",
      "brief": [
          "Full stack development for eQ’s DaaS (Data as a Service) platform."
      ]
    },
   {
    "company": "AI4Bhārat",
    "link": "https://ai4bharat.iitm.ac.in/",
    "role": "Full Stack Developer",
    "brief": [
        "Contributed to full stack development across multiple AI4Bharat platforms like Shoonya, Anudesh, Chitralekha.",
        "Streamlined the deployment for Shoonya using CLI and Automation scripts for varying development/production use cases, reducing manual configuration required to set up the platform by 100%.",
        "Implemented a decoupled architecture for the existing components like Celery, Redis, and Django to facilitate scaling needs of an increasing user base.",
        "Led the backend development for a major feature overhaul of Chitralekha, consequently simplifying user journey and reducing the time required for the completion of workflows by a factor of 3x.",
        "Deployed and integrated an LLM suite within the application to introduce a secondary transcription layer to improve translation performance for lengthy/complex transcripts.",
        "Leveraged Docker and Azure Cloud to manage backend deployments effectively, handling site reliability issues as they occur, minimizing user impact.",
        "Resolved bugs, introduced new features and optimizations across the board for all three platforms."
    ]
  }
  ,
  {
    company: "Toplyne",
    link: "https://www.toplyne.io/",
    role: "Software Developer Intern",
    brief: [
      "Spearheaded the development of a multi-tenant, scalable and flexible full stack Config Management System for the extensive configurations generated by different dev teams to aid SDLC across Toplyne.",
      "Implemented a novel state dependent versioning system by leveraging pythonic libraries, supporting both rollback and auditing capabilities.",
      "Enforced robust validation layers to reduce downstream misconfiguration errors by 90%.",
      "Designed the service to support reproduction and promotion of configuration across environments to allow replication of Toplyne's infrastructure.",
      "Developed an admin dashboard complete with authentication and RBAC for full control across all custom actions.",
      "Worked with Flask, SQL Alchemy, Alembic along with Postgres for database and Docker for DevOps. Managed the deployment lifecycle of the service on AWS.",
    ],
  },
  {
    company: "Nomura Holdings",
    link: "https://www.nomura.com/",
    role: "Software Developer Intern",
    brief: [
      "Developed a Feed Generator in Java Springboot along with utility classes and functions for data retrieval,filtering, and congregation into excel to generate a report based on given filter criteria and reduce manual effort by 90%",
      "Composed UNIX scripts and accelerated the data warehousing pipeline by automating the processes feed generations, packaging them with respective check-sums and .info files and publishing them to a central data warehouse.",
      "Mastered multiple tools in the Java application development and deployment pipeline, including package management using Maven, Testing using Jconsole, Versioning using Git and building using Jenkins.",
      "Developed a ReactJS frontend for an Automation as a Service (AaaS) application developed on Java to handle automated testing using the RobotFramework, reducing manual effort and redundancy.",
      "Created Natural Language Processing (SBERT) pipeline in Python along with MS excel to find similarity indexes through multiple sentence encoding algorithms and aggregate similar responses to business life cycle events to reduce duplication in controls across different regions and business units by 60%.",
    ],
  },
  {
    company: "Bitglaze Technologies",
    link: "https://www.linkedin.com/company/bitglaze-technologies/",
    role: "Web App Developer Intern",
    brief: [
      "Collaborated as a Full Stack Web Developer to design an Enterprise Resource Planning System with databases and implemented API(s) for multiple integrated modules such as Core (Departments, Designations, Employees management), Attendances, Leaves using PostgreSQL Django Rest Framework.",
      "Designed a custom renderer and parser for JSON objects for much improved data visualization and handling.",
      "Introduced MPTT to incorporate hierarchical models. Designed a custom backend filter for 30% efficient selective retrieval of data. Built Celery tasks for automation of tasks like leave generation and leave handling.",
      "Dockerized entire project to enable cross platform deployment and consistency.",
      "Generated Postman and Swagger API specs for easy testing and handling.",
      "Documented entire module in overleaf using LaTeX for trouble free handover.",
    ],
  },
];
